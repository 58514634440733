
import { ref, computed, defineComponent, PropType } from 'vue'

import { Subscription } from '@web/types/Subscription'

import { getNumberDecliner, getUrlWithAuthParams } from '@web/common/Utils'
import i18n from '@web/plugins/i18n'
import ModalCancelSubscription from './ModalCancelSubscription.vue'

import getBaseUrl from '@web/common/getBaseUrl'

import { currencyInfo } from '@ui-kit/util'

export default defineComponent({
  name: 'Subscription',
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true
    }
  },
  components: {
    ModalCancelSubscription
  },
  setup (props) {
    const showModalCancelSubscription = ref(false)

    const status = computed(() => props.subscription.status.toLowerCase())
    const currency = computed(() => currencyInfo().getCurrency(props.subscription.raw.currency)?.symbol || props.subscription.raw.currency)
    const period = computed(() => {
      if (props.subscription.raw.period && props.subscription.raw.interval) {
        const key = props.subscription.raw.interval.toLowerCase()
        const periodText = getNumberDecliner({
          n: props.subscription.raw.period,
          single: i18n.global.t(`subscription.${key}`),
          multiple: i18n.global.t(`subscription.${key}s`),
          multiple2: i18n.global.t(`subscription.${key}s2`)
        })

        return `${props.subscription.raw.period} ${periodText}`
      }

      return ''
    })
    const amountText = computed(() => {
      if (props.subscription.isLifetime) {
        return i18n.global.t('subscription.paidFor')
      }

      return `${props.subscription.raw.amount}${currency.value} <span>${i18n.global.t('subscription.every')} ${period.value}</span>`
    })

    const footerText = computed(() => props.subscription.isActive ? i18n.global.t('cancelSubscribeBtn.ready') : i18n.global.t('cancelSubscribeBtn.cancel'))
    const showFooter = computed(() => {
      if (props.subscription.isLifetime) {
        return false
      }
      return props.subscription.isActive ? props.subscription.isActiveStatus : true
    })

    const accessListCourse = computed(() => props.subscription.raw?.content?.courses?.map(item => item.title).join(', ') || '')
    const accessListClub = computed(() => props.subscription.raw?.content?.communities?.map(item => item.title).join(', ') || '')

    function openSubscription () {
      if (props.subscription.isActive) {
        showModalCancelSubscription.value = true
      } else {
        window.open(getUrlWithAuthParams(getBaseUrl(`/payment/subscription/extend?id=${props.subscription.id}&utm_source=lk&utm_medium=subscription_button`)), '_system')
      }
    }

    return {
      showModalCancelSubscription,
      status,
      period,
      amountText,
      footerText,
      showFooter,
      accessListCourse,
      accessListClub,
      openSubscription
    }
  }
})
